export const BASE_URL = "https://crypdev.paycircle.io";
// export const BASE_URL = "https://softapi.paycircle.io"

export const TOKEN_BASE_URL = "https://crypapidev.bsplinternal.com";

export const TRULIOO_URL = "https://crypwebui.bsplinternal.com/";
// export const TRULIOO_URL = "https://uataccount.paycircle.io/"

export const EXPLORER_URL = "https://testnetexplorer.diamcircle.io/";
// export const EXPLORER_URL = "https://explorer.diamcircle.io/"
