import React from "react";

const MyAccount = ({ setSelectWallet, selectWallet, setAddBank, addBank }) => {
  return (
    <div className="settingsRightSec">
      {/* <div className="settingsRightSecTop">
        <span>Linked bank</span>
        <span
          onClick={() => {
            navigate("../addBank");
          }}
          style={{ cursor: "pointer", color: "#00F0FF" }}
        >
          Add +
        </span>
      </div> */}
    </div>
  );
};

export default MyAccount;
