export const ActionTypes = {
  EMAIL_ID: "EMAIL_ID",
  PASSWORD_VALUE: "PASSWORD_VALUE",
  CUSTOMER_ID: "CUSTOMER_ID",
  USER_CONTACTNO: "USER_CONTACTNO",
  TWOFA_VALUE: "TWOFA_VALUE",
  REFERRALCODE: "REFERRALCODE",
  ISFROMDASHBOARD: "ISFROMDASHBOARD",
  CUSTOMERINFO: "CUSTOMERINFO",
};
